import React, { useState, useEffect } from 'react';
import NextImage from 'next/image';
import Text from '@/atoms/Text';
import PropTypes from 'prop-types';

const FloatingIcon = ({
    onClick,
    className = '',
    bgColor = 'bg-[#2AA81A]',
    icon = '/images/icons/icon_whatspp.svg',
    title = 'Chat With Us',
    position = 'fixed right-4 md:right-14 bottom-4 md:bottom-14',
}) => {
    const [show, setShow] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const onMouseEnter = () => {
        if (!isMobile) setShow(true);
    };

    const onMouseLeave = () => {
        if (!isMobile) setShow(false);
    };

    return (
        <div
            className={`z-[98] ${!show ? 'w-[56px] rounded-full' : 'w-full rounded-[40px]'} max-w-[172px] h-[56px] group transition-all duration-500 cursor-pointer  ${bgColor} flex items-center px-3.5 ${position} ${className}`}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
        >
            <div className="flex-none flex items-center justify-center">
                <NextImage src={icon} width={30} height={30} />
            </div>

            <Text
                variant="body4"
                textColor="text-white"
                fontWeight="font-medium"
                className={`${!show ? 'w-0 opacity-0' : ' opacity-100 w-auto  transition-all duration-500 whitespace-nowrap transform -translate-x-50 ml-3'}`}
            >
                {title}
            </Text>
        </div>
    );
};

FloatingIcon.propTypes = {
    onClick: PropTypes.func,
    className: PropTypes.string,
    icon: PropTypes.string,
    title: PropTypes.string,
    position: PropTypes.string,
    bgColor: PropTypes.string,
};
export default FloatingIcon;
