import NextImage from 'next/image';
import { useRef, useState, useEffect } from 'react';
import { Range, getTrackBackground } from 'react-range';
import PropTypes from 'prop-types';

const CustomVideo = ({ className, videoSrc, ...rest }) => {
    const videoRef = useRef();
    const [isPaused, setIsPaused] = useState(true);
    const [percentVidProgress, setPercentVidProgress] = useState([0]);
    const [volume, setVolume] = useState(1);
    const [isMuted, setIsMuted] = useState(true);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);

    const toggleVideo = () => {
        if (isPaused) {
            videoRef.current.play();
            setIsMuted(false);
        } else {
            videoRef.current.pause();
        }
        setIsPaused(!isPaused);
    };

    const handleLoadedMetadata = () => {
        setDuration(videoRef.current.duration);
    };

    const handleProgress = () => {
        const currentProgress =
            (videoRef.current.currentTime / videoRef.current.duration) * 100;
        setPercentVidProgress([currentProgress]);
        setCurrentTime(videoRef.current.currentTime);
    };

    const handleVolumeChange = (e) => {
        const updatedVolume = e.target.value;
        setVolume(updatedVolume);
        videoRef.current.volume = updatedVolume;
        if (updatedVolume === '0') {
            setIsMuted(true);
        } else {
            setIsMuted(false);
        }
    };

    const handleForward = () => {
        videoRef.current.currentTime += 15;
    };

    const handleBackward = () => {
        videoRef.current.currentTime -= 15;
    };

    const handleSeek = (values) => {
        const seekTime = (values[0] / 100) * videoRef.current.duration;
        videoRef.current.currentTime = seekTime;
        setPercentVidProgress(values);
        setCurrentTime(seekTime);
    };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    const handleFullscreen = () => {
        const isIOS =
            /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

        if (videoRef.current.requestFullscreen) {
            videoRef.current.requestFullscreen();
        } else if (videoRef.current.mozRequestFullScreen) {
            videoRef.current.mozRequestFullScreen();
        } else if (videoRef.current.webkitRequestFullscreen) {
            videoRef.current.webkitRequestFullscreen();
        } else if (videoRef.current.msRequestFullscreen) {
            videoRef.current.msRequestFullscreen();
        } else if (isIOS && videoRef.current.webkitEnterFullscreen) {
            videoRef.current.webkitEnterFullscreen();
        }
    };

    const toggleMute = () => {
        setIsMuted(!isMuted);
        videoRef.current.muted = !isMuted;
        if (!isMuted) {
            setVolume(0);
        } else {
            setVolume(1);
        }
    };

    useEffect(() => {
        videoRef.current.volume = volume;
        videoRef.current.muted = isMuted;
    }, [volume, isMuted]);

    return (
        <div className={`relative overflow-hidden player ${className}`}>
            <video
                ref={videoRef}
                className="w-full h-full object-cover"
                src={videoSrc}
                onClick={toggleVideo}
                onLoadedMetadata={handleLoadedMetadata}
                onTimeUpdate={handleProgress}
                onEnded={() => setIsPaused(true)}
                playsInline
                autoPlay={false}
                muted={isMuted}
                controls={false}
                preload="auto"
                {...rest}
            ></video>
            {isPaused && (
                <div
                    className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 cursor-pointer"
                    onClick={toggleVideo}
                >
                    <NextImage
                        src="/images/icons/icon_video.svg"
                        width={72}
                        height={72}
                        alt="Play"
                    />
                </div>
            )}
            <div
                className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer flex items-center gap-5"
                onClick={(e) => e.stopPropagation()}
            >
                {!isPaused && (
                    <NextImage
                        loader={({ src }) => src}
                        src="/images/icons/backward.png"
                        width={32}
                        height={32}
                        alt="backward"
                        onClick={handleBackward}
                    />
                )}
                <NextImage
                    src={
                        isPaused
                            ? '/images/icons/icon_video.svg'
                            : '/images/icons/pause.svg'
                    }
                    width={72}
                    height={72}
                    onClick={toggleVideo}
                />
                {!isPaused && (
                    <NextImage
                        loader={({ src }) => src}
                        src="/images/icons/forward.png"
                        width={32}
                        height={32}
                        alt="forward"
                        onClick={handleForward}
                    />
                )}
            </div>
            <div className="absolute bottom-[20px] flex items-end gap-3 w-[95%] left-1/2 -translate-x-1/2">
                <div className="w-full mb-1 ml-1 xsm:ml-0">
                    <Range
                        step={0.1}
                        min={0}
                        max={100}
                        values={percentVidProgress}
                        onChange={handleSeek}
                        renderTrack={({ props, children }) => (
                            <div
                                {...props}
                                style={{
                                    height: '6px',
                                    width: '100%',
                                    backgroundColor: '#EBEBEB',
                                    background: getTrackBackground({
                                        values: percentVidProgress,
                                        colors: ['#9D5CF3', '#EBEBEB'],
                                        min: 0,
                                        max: 100,
                                    }),
                                    alignSelf: 'center',
                                    cursor: 'pointer',
                                }}
                            >
                                {children}
                            </div>
                        )}
                        renderThumb={({ props }) => (
                            <div
                                {...props}
                                style={{
                                    height: '16px',
                                    width: '16px',
                                    backgroundColor: '#9D5CF3',
                                    borderRadius: '50%',
                                }}
                            />
                        )}
                    />
                </div>
                <div className="time-info text-xs text-white flex-shrink-0">
                    <span className="text-white">
                        {formatTime(currentTime)}
                    </span>{' '}
                    /{' '}
                    <span className="text-grey-shade5">
                        {formatTime(duration)}
                    </span>
                </div>
                <div className="relative flex flex-col-reverse justify-center items-center volume-container">
                    <NextImage
                        src={
                            isMuted
                                ? '/images/icons/icon_muted.svg'
                                : '/images/icons/icon_unmuted.svg'
                        }
                        width={24}
                        height={24}
                        onClick={((e) => e.stopPropagation(), toggleMute)}
                    />
                    <input
                        type="range"
                        name="volume"
                        className="player__slider"
                        min="0"
                        max="1"
                        step="0.05"
                        value={volume}
                        orient="vertical"
                        onChange={handleVolumeChange}
                        onClick={(e) => e.stopPropagation()}
                    />
                </div>

                <NextImage
                    loader={({ src }) => src}
                    src="/images/icons/fullscreen.svg"
                    width={24}
                    height={24}
                    onClick={handleFullscreen}
                    className="cursor-pointer"
                />
            </div>
        </div>
    );
};

CustomVideo.propTypes = {
    className: PropTypes.string,
    videoSrc: PropTypes.string.isRequired,
};

export default CustomVideo;
