import PropTypes from 'prop-types';
import { useState } from 'react';
import NextImage from 'next/image';
import Text from '@/atoms/Text';

const AccordionList = ({
    title,
    content,
    isActive,
    onClick,
    borderRadius = 'rounded-xl',
    className,
    ...rest
}) => {
    return (
        <div
            className={`p-3 bg-white cursor-pointer ${borderRadius}  ${className || ''}`}
            {...rest}
        >
            <div
                className="flex justify-between items-center"
                onClick={onClick}
            >
                <Text
                    variant="body2"
                    fontWeight="font-medium"
                    textColor="text-grey-shade1"
                >
                    {title}
                </Text>
                <div
                    className={`flex flex-shrink-0 transform duration-300 ${isActive ? '-rotate-180' : 'rotate-0'}`}
                >
                    <NextImage
                        src="/images/icons/icon_chevron_down.svg"
                        width="11"
                        height="7"
                        alt="up Arrow"
                    />
                </div>
            </div>
            <div
                className={`max-h-0 overflow-hidden transition-max-height duration-100 ease-out ${
                    isActive ? 'max-h-screen' : ''
                }`}
            >
                <div className="text-grey-shade3 pt-4">{content}</div>
            </div>
        </div>
    );
};

AccordionList.propTypes = {
    title: PropTypes.string.isRequired,
    className: PropTypes.string,
    content: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    borderRadius: PropTypes.string,
};

const Accordion = ({ accordionData, multipleOpen }) => {
    const [activeIndices, setActiveIndices] = useState([0]);

    const handleAccordionClick = (index) => {
        if (multipleOpen) {
            setActiveIndices((prevIndices) =>
                prevIndices.includes(index)
                    ? prevIndices.filter((item) => item !== index)
                    : [...prevIndices, index]
            );
        } else {
            if (activeIndices.includes(index)) {
                setActiveIndices([]);
            } else {
                setActiveIndices([index]);
            }
        }
    };

    return (
        <>
            {accordionData.map(({ title, content }, index) => (
                <AccordionList
                    key={index}
                    title={title}
                    content={content}
                    isActive={activeIndices.includes(index)}
                    onClick={() => handleAccordionClick(index)}
                    className="mb-6 last:mb-0"
                    data-aos="fade-up"
                    data-aos-once="true"
                    data-aos-delay={index * 100}
                />
            ))}
        </>
    );
};

Accordion.defaultProps = {
    accordionData: [],
    multipleOpen: false,
};

Accordion.propTypes = {
    accordionData: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            content: PropTypes.string.isRequired,
        })
    ).isRequired,
    multipleOpen: PropTypes.bool,
};

export default Accordion;
